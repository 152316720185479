<template>
     <div class="dashboard d-flex flex-column flex-shrink-0 p-3 text-white" style="width: 280px;">

    <hr>
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item">
        <h4 href="#" class="nav-link active" aria-current="page">
          Dashboard
        </h4>
      </li>
      <li>
        <h4 href="#" class="nav-link text-white">
          Database
        </h4>
      </li>
      <li>
        <h4 href="#" class="nav-link text-white">
          Add client
        </h4>
      </li>
      <li>
        <h4 href="#" class="nav-link text-white">
          Account
        </h4>
      </li>

    </ul>
    <hr>

    <div class="dropdown" >
      <button class="btn btn-secondary dropdown-toggle d-flex align-items-center text-white text-decoration-none" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown">
        <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2">
        <strong class="pl-3">_User_Name</strong>
      </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" href="#">Account</a>
    <!-- <a class="dropdown-item" href="#">Settings</a> -->
    <a class="dropdown-item" href="#">Logout</a>
  </div>
    </div>
  </div>
</template>

<script>
    export default {
        data() {
            return {
            };
        },
        computed: {
        },
        methods: {
        }
    };
</script>

<style lang="scss" scoped>
.dashboard{
    background-color: #93a5b7;
    .nav-link{
        &.active{
        background-color: turquoise;
        }
    }
}
#dropdownMenuButton{
    width: 100%;
}
</style>