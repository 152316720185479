<template>
<Sidebar />
</template>

<script>
import Sidebar from '@/components/Sidebar'
    export default {
        data() {
            return {
                    currentDashboardSection: null,

            };
        },
        computed: {
        },
        methods: {
        },
        components: {
          Sidebar
        }
    };
</script>

<style lang="scss" scoped>
.dashboard{
    height: 100%;
}
</style>